@import url("../node_modules/bootstrap/dist/css/bootstrap.css");
@import url("../node_modules/react-metismenu/dist/react-metismenu-standart.min.css");
@import url("./components/style.css");

#th-list-table {
  text-align: center;
}

#button-editar-senha {
  margin-top: 21px;
  width: 100%;
}

#marker-pupil {
  cursor: move;
  color: "blue";
  opacity: 1;
}

.fontHeader {
  font-weight: bold;
  /* font-family: 'Times New Roman', Times, serif; */
}

.filter {
  cursor:pointer;
}

body {
  overflow-y: scroll !important;
  padding-right: 0 !important;
  background-color: #f5f6f8 !important;
}

.form-select {
  color: #5c6873 !important;
  border-color: #e4e7ea !important;
  height: calc(1.5em + 0.75rem + 2px) !important;
}
